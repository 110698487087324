import { TagTypes } from '@shared/api/client';

import {
  enhancedApi,
  LoginAuthSignInPostApiArg,
  LoginAuthSignInPostApiResponse,
} from './sdkGenerated';

// @ts-ignore
export const sdk = enhancedApi
  .injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation<
        LoginAuthSignInPostApiResponse,
        LoginAuthSignInPostApiArg
      >({
        query: (queryArg) => ({
          // @ts-ignore
          body: new URLSearchParams(queryArg.bodyLoginAuthSignInPost),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          method: 'POST',
          url: `/auth/sign_in`,
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: Object.values(TagTypes),
    endpoints: {
      activateUserUserVerifyPost: {
        invalidatesTags: (result, error, { userVerify: { user_id } }) =>
          result
            ? [TagTypes.UserAdmin, { id: user_id, type: TagTypes.UserAdmin }]
            : [],
      },
      addUserPersonalDataUserAddPersonalDataPost: {
        invalidatesTags: (result) =>
          result ? [TagTypes.User, TagTypes.UserAdmin] : [],
      },
      cancelBookingByUserBookingCancelByUserPost: {
        invalidatesTags: (result, error, { bookingId }) =>
          result
            ? [{ id: bookingId, type: TagTypes.Booking }, TagTypes.Booking]
            : [],
      },
      createNewBookingBookingCreatePost: {
        invalidatesTags: (
          result,
          error,
          { bookingCreateFromApi: { house_id } },
        ) =>
          result
            ? [
                { id: house_id, type: TagTypes.House },
                TagTypes.Booking,
                TagTypes.House,
              ]
            : [],
      },
      createNewHouseHouseCreatePost: {
        invalidatesTags: (result) => (result ? [TagTypes.House] : []),
      },
      createNewHousePricingHousePricingCreatePost: {
        invalidatesTags: (result) =>
          result ? [TagTypes.TariffPriceAdmin] : [],
      },
      createNewOperationRemOpsCreatePost: {
        invalidatesTags: (
          result,
          error,
          { remoteOperationCreateFromApi: { house_id } },
        ) =>
          result
            ? [TagTypes.Operation, { id: house_id, type: TagTypes.House }]
            : [],
      },
      createNewTariffTariffCreatePost: {
        invalidatesTags: (result) => (result ? [TagTypes.Tariff] : []),
      },
      deactivateUserUserDeactivatePost: {
        invalidatesTags: (result, error, { userDeactivate: { user_id } }) =>
          result
            ? [TagTypes.UserAdmin, { id: user_id, type: TagTypes.UserAdmin }]
            : [],
      },
      deleteHouseHouseDeletePost: {
        invalidatesTags: (result) => (result ? [TagTypes.House] : []),
      },
      deleteHousePricingHousePricingDeletePost: {
        invalidatesTags: (result) =>
          result ? [TagTypes.TariffPriceAdmin] : [],
      },
      deleteTariffTariffDeletePost: {
        invalidatesTags: (result) => (result ? [TagTypes.Tariff] : []),
      },
      getBookingByIdBookingGetIdGet: {
        providesTags: (result, error, { id }) =>
          result ? [{ id, type: TagTypes.Booking }] : [],
      },
      getHouseByIdHouseGetIdGet: {
        providesTags: (result, error, { id }) =>
          result ? [{ id, type: TagTypes.House }] : [],
      },
      getHouseByIdWithExtendedTariffInfoHouseGetExtIdGet: {
        providesTags: (result, error, { id }) =>
          result ? [{ id, type: TagTypes.House }] : [],
      },
      getHousePricingByHouseAndTariffOnDateRangeHousePricingGetRangeDatesGet: {
        providesTags: (result) => (result ? [TagTypes.TariffPriceAdmin] : []),
      },
      getTariffByIdTariffGetIdGet: {
        providesTags: (result, error, { id }) =>
          result ? [{ id, type: TagTypes.Tariff }] : [],
      },
      getUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGet: {
        providesTags: (result, error, { houseId }) =>
          result ? [{ id: houseId, type: TagTypes.House }] : [],
      },
      getUserVerificationInfoByIdUserUserIdUserIdGet: {
        providesTags: (result, error, { userId }) =>
          result ? [{ id: userId, type: TagTypes.UserAdmin }] : [],
      },
      listAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGet: {
        providesTags: [TagTypes.House],
      },
      listBookingsWithFiltersAndPaginationBookingListGet: {
        providesTags: [TagTypes.House, TagTypes.Booking],
      },
      listHousesAvailableForBookingWithFiltersAndPaginationHouseListAvailableGet:
        {
          providesTags: (result) =>
            result
              ? result.houses.map(({ id }) => ({ id, type: TagTypes.House }))
              : [],
        },
      listHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGet:
        {
          providesTags: (result) =>
            result
              ? result.houses.map(({ id }) => ({ id, type: TagTypes.House }))
              : [],
        },
      listHousesForAdminWithPaginationHouseAdminViewListGet: {
        providesTags: (result) =>
          result
            ? result.houses.map(({ id }) => ({ id, type: TagTypes.House }))
            : [],
      },
      listHousesToRepresentOnMapWithFiltersHouseListForMapGet: {
        providesTags: (result) =>
          result
            ? result.houses.map(({ id }) => ({ id, type: TagTypes.House }))
            : [],
      },
      listHousesWithFiltersAndPaginationHouseListGet: {
        providesTags: (result) =>
          result
            ? result.houses.map(({ id }) => ({ id, type: TagTypes.House }))
            : [],
      },
      listHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGet: {
        providesTags: (result) =>
          result
            ? result.houses.map(({ id }) => ({ id, type: TagTypes.House }))
            : [],
      },
      listRemoteOperationsForUserAndSelectedHouseWithFiltersAndPaginationRemOpsListForUserReprGet:
        {
          providesTags: [TagTypes.Operation],
        },
      listTariffsWithFiltersAndPaginationTariffListGet: {
        providesTags: (result) =>
          result
            ? result.tariffs.map(({ id }) => ({ id, type: TagTypes.Tariff }))
            : [],
      },
      listUsersAwaitingConfirmationPaginationUserListAwaitingConfirmationGet: {
        providesTags: (result) =>
          result
            ? result.users.map(({ id }) => ({
                id,
                type: TagTypes.UserAdmin,
              }))
            : [],
      },
      listUsersWithFiltersAndPaginationUserListGet: {
        providesTags: (result) =>
          result
            ? result.users.map(({ id }) => ({
                id,
                type: TagTypes.UserAdmin,
              }))
            : [],
      },
      makeNewPaymentPaymentMakePaymentPost: {
        invalidatesTags: (result, error, { bookingId }) => [
          { id: bookingId, type: TagTypes.Booking },
          TagTypes.Booking,
        ],
      },
      meUserMeGet: {
        providesTags: [TagTypes.User],
      },
      setHouseAvailableForBookingHouseActivatePost: {
        invalidatesTags: (result, error, { id }) =>
          result ? [{ id, type: TagTypes.House }, TagTypes.House] : [],
      },
      setHouseUnavailableForBookingHouseDeactivatePost: {
        invalidatesTags: (result, error, { id }) =>
          result ? [{ id, type: TagTypes.House }, TagTypes.House] : [],
      },
      setTariffAvailableForUsingTariffActivatePost: {
        invalidatesTags: (result, error, { id }) =>
          result ? [{ id, type: TagTypes.Tariff }, TagTypes.Tariff] : [],
      },
      setTariffUnavailableForUsingTariffDeactivatePost: {
        invalidatesTags: (result, error, { id }) =>
          result ? [{ id, type: TagTypes.Tariff }, TagTypes.Tariff] : [],
      },
      updateHouseHouseUpdatePost: {
        invalidatesTags: (result, error, { houseUpdate: { id } }) =>
          result ? [{ id, type: TagTypes.House }] : [],
      },
      updateHousePricingHousePricingUpdatePost: {
        invalidatesTags: (result) =>
          result ? [TagTypes.TariffPriceAdmin] : [],
      },
      updateTariffTariffUpdatePost: {
        invalidatesTags: (result, error, { tariffUpdate: { id } }) =>
          result ? [{ id, type: TagTypes.Tariff }] : [],
      },
    },
  });
