import { FC } from 'react';
import cx from 'classnames';

import { DateInput, RangeInput } from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/store';
import { resetFilterData, setFilterData } from '@features/House';
import { FilterItemWrapper } from '@features/House/ui/FilterItemWrapper/FilterItemWrapper';
import { Button, CloseIcon, Select } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

import styles from './styles.module.scss';

interface HousesListSideFilterProps {
  active: boolean;
  onClose(): void;
}
export const HousesListSideFilter: FC<HousesListSideFilterProps> = ({
  active,
  onClose,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const form = useAppSelector((state) => state.filter);
  const { data } =
    sdk.useListAllLocationsIdentsForSelectingHouseListAllLocationsIdentsGetQuery();

  const areas = data?.areas || [];
  const blocks = data?.blocks || [];
  const regions = data?.regions || [];

  return (
    <div className={cx({ [styles.filter]: true, [styles.opened]: active })}>
      <div className={styles.filter__inner}>
        <div className={styles.filter__head}>
          <div className={styles.filter__title}>Фильтр</div>
          <div className={styles.filter__close}>
            <Button
              prependIcon={CloseIcon}
              icon
              variant="text"
              size="large"
              onClick={onClose}
            />
          </div>
        </div>
        <div className={styles.filter__wrapper}>
          <div className={styles.filter__item}>
            <FilterItemWrapper
              filterSlot={
                <Select
                  fullWidth
                  placeholder="Выберите регион"
                  customValue={form.region!}
                  items={regions}
                  disabled={regions.length === 0}
                  onChange={(e) => dispatch(setFilterData({ region: +e }))}
                  hideOnSelect
                  // @ts-ignore
                  initValue={form.region!}
                />
              }
              title="Регион"
            />
          </div>
          <div className={styles.filter__item}>
            <FilterItemWrapper
              filterSlot={
                <Select
                  fullWidth
                  placeholder="Выберите район"
                  customValue={form.area!}
                  items={areas}
                  disabled={areas.length === 0}
                  onChange={(e) => dispatch(setFilterData({ area: +e }))}
                  hideOnSelect
                  // @ts-ignore
                  initValue={form.area!}
                />
              }
              title="Район"
            />
          </div>
          <div className={styles.filter__item}>
            <FilterItemWrapper
              filterSlot={
                <Select
                  fullWidth
                  placeholder="Выберите блок"
                  customValue={form.block!}
                  items={blocks}
                  disabled={blocks.length === 0}
                  onChange={(e) => dispatch(setFilterData({ block: +e }))}
                  hideOnSelect
                  // @ts-ignore
                  initValue={form.block!}
                />
              }
              title="Блок"
            />
          </div>
          {form.showAvailable && (
            <>
              <div className={styles.filter__item}>
                <FilterItemWrapper
                  filterSlot={
                    <DateInput
                      onChange={([start_date, end_date]) => {
                        dispatch(setFilterData({ start_date }));
                        dispatch(setFilterData({ end_date }));
                      }}
                      value={[form.start_date, form.end_date]}
                      placeholder="Дата"
                    />
                  }
                  title="Даты"
                />
              </div>
              <div className={styles.filter__item}>
                <FilterItemWrapper
                  filterSlot={
                    <RangeInput
                      onChange={(e) =>
                        dispatch(setFilterData({ max_cost_per_day: e }))
                      }
                      min={0}
                      max={10_000}
                      step={250}
                      value={form.max_cost_per_day}
                      placeholder="Цена за день"
                    />
                  }
                  title="Максимальная цена за день"
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.filter__btns}>
          <Button
            className={cx(styles.filter__btn, styles['filter__btn--mobile'])}
            onClick={onClose}>
            Применить
          </Button>
          <Button
            color="secondary"
            fullWidth
            size="large"
            onClick={() => dispatch(resetFilterData())}>
            Сбросить
          </Button>
        </div>
      </div>
    </div>
  );
};
