import React, { FC, useMemo, useState } from 'react';
import moment from 'moment/moment';

import {
  Button,
  Divider,
  LoaderOverlay,
  Maybe,
  Spacer,
  TextField,
  Typography,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { handleNumberInput } from '@shared/libs';
import { getIsoDate } from '@shared/libs/utils/format';
import {
  Calendar,
  CalendarChangeHandler,
  CalendarDay,
  CalendarSelected,
  OverbookHandler,
} from '@shared/ui/Calendar';

import { usePricingAdminCalendarDates } from '../../libs/hooks';

import styles from './styles.module.scss';

export interface HouseTariffDatePriceProps {
  tariffId: number;
  houseId: number;
}
export const AdminHouseTariffDatePrice: FC<HouseTariffDatePriceProps> = ({
  houseId,
  tariffId,
}) => {
  const [editedDate, setEditedDate] = useState<Maybe<Date>>(null);
  const [activeDays, setActiveDays] = useState<CalendarDay[]>([]);
  const [selected, setSelected] = useState<CalendarSelected[]>([]);
  const [price, setPrice] = useState<number>();
  const [createPricing, { isLoading: isLoadingCreate }] =
    sdk.useCreateNewHousePricingHousePricingCreatePostMutation();
  const [deletePricing, { isLoading: isLoadingDelete }] =
    sdk.useDeleteHousePricingHousePricingDeletePostMutation();
  const [updatePricing, { isLoading: isLoadingUpdate }] =
    sdk.useUpdateHousePricingHousePricingUpdatePostMutation();
  const pricingHouseParams = useMemo(
    () => ({
      activeInterval: activeDays?.length
        ? ([activeDays[0].date, activeDays.at(-1)!.date] as [Date, Date])
        : null,
      houseId,
      tariffId,
    }),
    [activeDays, houseId, tariffId],
  );
  const { labels, prices, reserved } =
    usePricingAdminCalendarDates(pricingHouseParams);
  const loading = isLoadingDelete || isLoadingCreate || isLoadingUpdate;

  const handleCreatePricing = async () => {
    if (selected.length === 0) {
      return;
    }
    await createPricing({
      housePricingCreateRange: {
        end_date: getIsoDate(new Date(selected[1]! || selected[0]!)),
        house_id: houseId,
        price: price!,
        start_date: getIsoDate(new Date(selected[0]!)),
        tariff_id: tariffId,
      },
    });
    setSelected([]);
  };
  const handleDeletePricing = async () => {
    if (!editedDate) {
      return null;
    }
    await deletePricing({
      housePricingDelete: {
        date: getIsoDate(editedDate!),
        house_id: houseId,
        tariff_id: tariffId,
      },
    });
    setEditedDate(null);
    setPrice(0);
  };
  const handleUpdatePricing = async () => {
    if (!editedDate || !price) {
      return null;
    }
    await updatePricing({
      housePricingUpdate: {
        date: getIsoDate(editedDate!),
        house_id: houseId,
        price,
        tariff_id: tariffId,
      },
    });
    setEditedDate(null);
    setPrice(0);
  };

  const handleChangeSelected: CalendarChangeHandler = (value) => {
    setPrice(0);
    setSelected(value);
    setEditedDate(null);
  };
  const handleOverbookClick: OverbookHandler = (date, overbookType) => {
    setSelected([]);
    if (overbookType === 'BOOKED') {
      const priceItem = prices.find(
        (el) => +moment(el.date).startOf('day').toDate() === +date,
      );

      setEditedDate(date);
      setPrice(priceItem?.price || 0);
    } else {
      setEditedDate(null);
      setPrice(0);
    }
  };

  return (
    <div className={styles.housePrice}>
      <LoaderOverlay show={loading} />
      <Calendar
        selected={selected}
        reserved={reserved}
        labels={labels}
        range
        protection
        options={{ locale: 'ru', useAttributes: true, weekStartsOn: 1 }}
        onChange={handleChangeSelected}
        onChangeActiveDays={setActiveDays}
        onOverbook={handleOverbookClick}
        disabled
      />
      <Divider vertical />
      {selected.length > 0 ? (
        <div>
          <Typography variant="head-20">
            Установка цены на даты {getIsoDate(new Date(selected[0]!))}
            {selected[1] ? ` - ${getIsoDate(new Date(selected[1]!))}` : ''}
          </Typography>
          <Spacer size={16} />
          <TextField
            size="large"
            initValue={price ? String(price) : ''}
            onChange={(e) => setPrice(+e.target.value)}
            onKeyPress={(e) => handleNumberInput(e, true)}
            fullWidth
          />
          <Spacer size={12} />
          <Button fullWidth disabled={!price} onClick={handleCreatePricing}>
            Установить спец цену
          </Button>
        </div>
      ) : editedDate ? (
        <div>
          <Typography variant="head-20">
            Обновление цены на дату {getIsoDate(new Date(editedDate))}
          </Typography>
          <Spacer size={16} />
          <TextField
            key={+editedDate}
            size="large"
            initValue={price ? String(price) : ''}
            onChange={(e) => setPrice(+e.target.value)}
            onKeyPress={(e) => handleNumberInput(e, true)}
            fullWidth
          />
          <Spacer size={12} />
          <Button fullWidth disabled={!price} onClick={handleUpdatePricing}>
            Обновить спец цену
          </Button>
          <Spacer size={8} />
          <Button fullWidth color="danger" onClick={handleDeletePricing}>
            Удалить спец цену
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
