import { FC, useEffect } from 'react';

import { DateSingleInput } from '@/shared';
import { FilterItemWrapper } from '@features/House/ui/FilterItemWrapper/FilterItemWrapper';
import { Select, useForm } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

import { AdminUsersFilters } from '../AdminUsersTable/AdminUsersTable';

import styles from './styles.module.scss';

const initialValues: AdminUsersFilters = {};

export interface AdminUsersListFilterProps {
  onChange(val: AdminUsersFilters): void;
}
const statuses = [
  { label: 'Все', value: 'all' },
  { label: 'Верифицированные', value: 'active' },
  { label: 'Не верифицированные', value: 'notActive' },
];

export const AdminUsersListFilter: FC<AdminUsersListFilterProps> = ({
  onChange,
}) => {
  const { data: rolesData } = sdk.useGetRolesRoleAllGetQuery();
  const roles = rolesData?.roles?.length
    ? ['Все', ...rolesData.roles.map((el) => el.name!)]
    : [];
  const { setBatchValues, values } = useForm<AdminUsersFilters>(
    console.log,
    initialValues,
  );

  useEffect(() => {
    onChange(values);
  }, [values]);

  return (
    <div className={styles.houseListFilter}>
      <FilterItemWrapper
        filterSlot={
          <Select
            fullWidth
            initValue={{ label: 'Все', value: 'all' }}
            items={statuses}
            trackKey="value"
            labelKey="label"
            onChange={(val) =>
              setBatchValues({
                isActive:
                  val.value === 'active'
                    ? true
                    : val.value === 'notActive'
                      ? false
                      : undefined,
              })
            }
            hideOnSelect
          />
        }
        title="Статус"
        slim
      />
      <FilterItemWrapper
        filterSlot={
          <Select
            fullWidth
            items={roles}
            onChange={(val) =>
              setBatchValues({
                role: val === 'Все' ? undefined : val,
              })
            }
            hideOnSelect
            placeholder="Выберите роль"
          />
        }
        title="Роль"
        slim
      />
      <FilterItemWrapper
        filterSlot={
          <DateSingleInput
            onChange={(value) => {
              setBatchValues(
                value
                  ? {
                      createdAt: value.toISOString(),
                      isCreationDateBefore: true,
                    }
                  : { createdAt: undefined, isCreationDateBefore: undefined },
              );
            }}
            value={values.createdAt ? new Date(values.createdAt) : null}
            placeholder="Дата регистрации до"
          />
        }
        title="Дата регистрации до"
        slim
      />
      <FilterItemWrapper
        filterSlot={
          <DateSingleInput
            onChange={(value) => {
              setBatchValues(
                value
                  ? {
                      deletedAt: value.toISOString(),
                      isDeletionDateBefore: true,
                    }
                  : { deletedAt: undefined, isDeletionDateBefore: undefined },
              );
            }}
            value={values.createdAt ? new Date(values.createdAt) : null}
            placeholder="Дата удаления до"
          />
        }
        title="Дата удаления до"
        slim
      />
    </div>
  );
};
