import { FC, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { useAppDispatch, useAppSelector } from '@app/store';
import { HouseMiniCard, HousesNotFound } from '@entities/House';
import { HousesMap } from '@entities/House/ui/HousesMap/HousesMap';
import {
  HousesListSideFilter,
  HousesListTopFilter,
  initFilterData,
  resetFilterData,
  setFilterData,
} from '@features/House';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  LoaderOverlay,
  Pagination,
  Scroll,
  Spacer,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { getIsoDate, pagination } from '@shared/libs/utils/format';

import { HousesCountInfo } from '../HousesCountInfo/HousesCountInfo';

import styles from './styles.module.scss';

const pageSize = 5;

export const HousesList: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const filterData = useAppSelector((state) => state.filter);
  const [sideFilterVisible, setSideFilterVisible] = useState(false);
  const [wideMap, setWideMap] = useState(false);

  const baseFilters = {
    area: filterData.area || undefined,
    block: filterData.block || undefined,
    region: filterData.region || undefined,
  };
  const {
    data: dataAll,
    isFetching: isLoadingAll,
    isSuccess: isSuccessAll,
  } = sdk.useListHousesWithFiltersAndPaginationWithExtendedTariffInfoHouseListExtGetQuery(
    {
      ...pagination(filterData.page, pageSize),
      ...baseFilters,
    },
  );
  const {
    data: dataAvailable,
    isFetching: isLoadingAvailable,
    isSuccess: isSuccessAvailable,
  } = sdk.useListHousesAvailableForBookingWithFiltersAndPaginationWithExtendedTariffInfoHouseListAvailableExtGetQuery(
    {
      ...pagination(filterData.page, pageSize),
      ...baseFilters,
      endDate: filterData.end_date
        ? getIsoDate(filterData.end_date)
        : undefined,
      maxCostPerDay: filterData.max_cost_per_day,
      startDate: filterData.start_date
        ? getIsoDate(filterData.start_date)
        : undefined,
    },
  );
  const isLoading = isLoadingAvailable || isLoadingAll;
  const data = useMemo(() => {
    const emptyData = {
      edge: {
        total: 0,
      },
      houses: [],
    };

    if (filterData.showAvailable) {
      return isSuccessAvailable ? dataAvailable : emptyData;
    }

    return isSuccessAll ? dataAll : emptyData;
  }, [
    dataAll,
    dataAvailable,
    filterData.showAvailable,
    isSuccessAvailable,
    isSuccessAll,
  ]);
  const pageCount = Math.ceil(Number(data?.edge.total) / pageSize);

  const isNotebook = useMediaQuery({
    query: '(max-width: 1023px)',
  });

  useEffect(() => {
    dispatch(initFilterData());
  }, []);

  return (
    <div className={cx(styles.houses, wideMap && styles['houses--mapWide'])}>
      <div className={styles.houses__controls}>
        <Button size="large" onClick={() => setSideFilterVisible(true)}>
          Фильтр
        </Button>
        <Button
          size="large"
          color="secondary"
          onClick={() => setWideMap(!wideMap)}>
          {wideMap ? 'Список' : 'Карта'}
        </Button>
      </div>
      <div className={styles.houses__wrapper}>
        <HousesListSideFilter
          onClose={() => setSideFilterVisible(false)}
          active={sideFilterVisible}
        />
        <div className={styles.houses__items}>
          <HousesCountInfo
            count={data?.edge.total || 0}
            className={styles.houses__info}
          />
          <Spacer size={8} />
          <HousesListTopFilter />
          <Spacer size={8} />
          <div className={styles.houses__content}>
            <LoaderOverlay show={isLoading} />
            {data?.houses?.length ? (
              <Scroll className={styles.houses__scrollWrapper}>
                <div className={styles.houses__result}>
                  {data?.houses.map((house) => (
                    <HouseMiniCard key={house.id} {...house} />
                  ))}
                </div>
                <Spacer size={8} />
                {pageCount > 1 && (
                  <Pagination
                    pageCount={pageCount}
                    page={filterData.page}
                    onChange={(page) => dispatch(setFilterData({ page }))}
                  />
                )}
                <Spacer size={8} />
              </Scroll>
            ) : (
              <HousesNotFound onReset={() => dispatch(resetFilterData())} />
            )}
          </div>
        </div>
      </div>
      {(wideMap || !isNotebook) && (
        <div className={styles.houses__map}>
          <HousesMap />
          <Button
            className={styles.houses__button}
            variant="outlined"
            prependIcon={wideMap ? ArrowRightIcon : ArrowLeftIcon}
            onClick={() => setWideMap(!wideMap)}>
            {wideMap ? 'Свернуть карту' : 'Развернуть карту'}
          </Button>
        </div>
      )}
    </div>
  );
};
