import { FC, useMemo, useState } from 'react';

import {
  Button,
  Checkbox,
  LoaderOverlay,
  TableController,
} from '@house-booking/ui-kit';
import { sdk, TariffAdminViewResponse } from '@shared/api';
import { formatAmount, pagination } from '@shared/libs';

const pageSize = 5;

export interface AdminTariffPickerProps {
  initValue: number[];
  onChange(val: number[]): void;
  editPrice?: boolean;
  onEditPrice?(id: number): void;
}
export const AdminTariffPicker: FC<AdminTariffPickerProps> = ({
  editPrice,
  initValue,
  onChange,
  onEditPrice,
}) => {
  const headers = [
    { key: true, value: 'id', width: 60 },
    { value: 'name' },
    { value: 'class' },
    { value: 'base_price' },
    ...(editPrice ? [{ value: 'editPrice' }] : []),
    { value: 'check' },
  ];
  const [value, setValue] = useState<number[]>(initValue);
  const [page, setPage] = useState(1);

  const { data, isFetching } =
    sdk.useListTariffsWithFiltersAndPaginationTariffListGetQuery(
      pagination(page, pageSize),
    );

  const handleChange = (id: number, val: boolean) => {
    const newValues = val ? [...value, id] : value.filter((el) => el !== id);

    onChange(newValues);
    setValue(newValues);
  };

  const rowTemplate = (tariff: TariffAdminViewResponse) => ({
    base_price: tariff.base_price
      ? String(formatAmount(tariff.base_price))
      : '-',
    check: (
      <div className="d-flex-center">
        <Checkbox
          checked={value.includes(tariff.id!)}
          onChange={(e) => handleChange(tariff.id!, e.target.checked)}
        />
      </div>
    ),
    class: tariff.tariff_class || '-',
    editPrice: value.includes(tariff.id!) ? (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => onEditPrice && onEditPrice(tariff.id!)}>
          Цены
        </Button>
      </div>
    ) : (
      ' '
    ),
    id: tariff.id!,
    name: tariff.tariff_name || '-',
  });
  const items = useMemo(
    () => (data?.tariffs || []).map((item) => rowTemplate(item)),
    [data, value],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={isFetching} />
      <TableController
        data={items}
        headers={headers}
        total={data?.edge.total}
        onChangePage={setPage}
        pageSize={pageSize}
        notResetPage
      />
    </div>
  );
};
