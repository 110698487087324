import { FC } from 'react';

import { useAppDispatch, useAppSelector } from '@app/store';
import { setFilterData } from '@features/House';
import { Switch, Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export const HousesListTopFilter: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const filterData = useAppSelector((state) => state.filter);

  return (
    <div className={styles.topFilter}>
      <div className={styles.topFilter__switch}>
        <Typography variant="body-14">
          Показать только доступные дома
        </Typography>
        <Switch
          onChange={(e) =>
            dispatch(setFilterData({ showAvailable: e.target.checked }))
          }
          checked={filterData.showAvailable}
        />
      </div>
    </div>
  );
};
