import { useMemo } from 'react';

import { sdk } from '@shared/api';
import { formatAmount, getIsoDate } from '@shared/libs/utils/format';
import { CalendarLabel, CalendarReserved } from '@shared/ui/Calendar/types';

export interface UsePricingHouseDatesProps {
  houseId: number;
  tariffId: number;
  activeInterval: [Date, Date] | null;
}
export const usePricingAdminCalendarDates = ({
  activeInterval,
  houseId,
  tariffId,
}: UsePricingHouseDatesProps) => {
  const { data } =
    sdk.useGetHousePricingByHouseAndTariffOnDateRangeHousePricingGetRangeDatesGetQuery(
      {
        endDate: getIsoDate(activeInterval?.[1] || new Date()),
        houseId,
        startDate: getIsoDate(activeInterval?.[0] || new Date()),
        tariffId,
      },
      { skip: !activeInterval },
    );

  const labels = useMemo<CalendarLabel[]>(
    () =>
      (data?.date_price_records || []).map((el) => ({
        date: new Date(el.date!),
        label: formatAmount(el.price!),
      })),
    [data],
  );

  const reserved = useMemo<CalendarReserved[]>(
    () =>
      (data?.date_price_records || []).map((el) => ({
        endDate: new Date(el.date!),
        startDate: new Date(el.date!),
      })),
    [data],
  );

  return {
    labels,
    prices: data?.date_price_records || [],
    reserved,
  };
};
