import { FilterData } from '@features/House';

const toQueryParams = (data: Partial<FilterData>): URLSearchParams => {
  const params = new URLSearchParams();

  if (typeof data.showAvailable === 'boolean') {
    params.append('showAvailable', String(data.showAvailable));
  }
  if (data.region) {
    params.append('region', String(data.region));
  }
  if (data.area) {
    params.append('area', String(data.area));
  }
  if (data.block) {
    params.append('block', String(data.block));
  }
  if (data.max_cost_per_day) {
    params.append('max_cost_per_day', String(data.max_cost_per_day));
  }
  if (data.start_date) {
    params.append('start_date', data.start_date.toISOString());
  }
  if (data.end_date) {
    params.append('end_date', data.end_date.toISOString());
  }
  if (data.coords) {
    params.append('coords', JSON.stringify(data.coords));
  }
  if (data.zoom) {
    params.append('zoom', String(data.zoom));
  }
  if (data.page) {
    params.append('page', String(data.page));
  }

  return params;
};

export const setFiltersParamsToURL = (data: Partial<FilterData>) => {
  const params = toQueryParams(data);

  const newUrl = `${window.location.pathname}?${params.toString()}`;

  window.history.replaceState(null, '', newUrl);
};

const fromQueryParams = (params: URLSearchParams): Partial<FilterData> => {
  const result: Partial<FilterData> = {};

  if (params.has('showAvailable')) {
    result.showAvailable = params.get('showAvailable') === 'true';
  }
  if (params.has('region')) {
    result.region = Number.parseInt(params.get('region')!);
  }
  if (params.has('area')) {
    result.area = Number.parseInt(params.get('area')!);
  }
  if (params.has('block')) {
    result.block = Number.parseInt(params.get('block')!);
  }
  if (params.has('max_cost_per_day')) {
    result.max_cost_per_day = Number.parseFloat(
      params.get('max_cost_per_day')!,
    );
  }
  if (params.has('page')) {
    result.page = Number.parseFloat(params.get('page')!);
  }
  if (params.has('start_date')) {
    result.start_date = new Date(params.get('start_date')!);
  }
  if (params.has('end_date')) {
    result.end_date = new Date(params.get('end_date')!);
  }
  if (params.has('coords')) {
    result.coords = JSON.parse(params.get('coords')!);
  }
  if (params.has('zoom')) {
    result.zoom = Number.parseInt(params.get('zoom')!);
  }

  return result;
};

// Usage in your app
export const getFilterParamsFromURL = (): Partial<FilterData> => {
  const params = new URLSearchParams(window.location.search);

  return fromQueryParams(params);
};
