import { CalendarReserved } from '../types';

import { endOfDay, isSameDay, startOfDay } from './date.utils';

export interface GetProtectedTimeReturn {
  startDate: Date;
  endDate: Date;
}

export const getProtectedTime = (
  date: Date,
  reserved: CalendarReserved[],
): GetProtectedTimeReturn => {
  const initTime = {
    endDate: endOfDay(date),
    startDate: startOfDay(date),
  };

  const reserveEnd = reserved.find((d) => isSameDay(d.endDate, date));
  const reserveStart = reserved.find((d) => isSameDay(d.startDate, date));

  if (reserveEnd) {
    initTime.startDate = new Date(reserveEnd.endDate);
  }
  if (reserveStart) {
    initTime.endDate = new Date(reserveStart.startDate);
  }

  return initTime;
};
