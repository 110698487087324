import {
  getFilterParamsFromURL,
  setFiltersParamsToURL,
} from '@features/House/libs/helpers';
import { Maybe } from '@house-booking/ui-kit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FilterData {
  showAvailable: boolean;
  region: Maybe<number>;
  area: Maybe<number>;
  block: Maybe<number>;
  max_cost_per_day: number | undefined;
  start_date: Maybe<Date>;
  end_date: Maybe<Date>;
  coords: Maybe<[number, number]>;
  zoom: Maybe<number>;
  page: number;
  isLoaded: boolean;
}

export const initialStateFilter: FilterData = {
  area: null,
  block: null,
  coords: null,
  end_date: null,
  isLoaded: false,
  max_cost_per_day: undefined,
  page: 1,
  region: null,
  showAvailable: false,
  start_date: null,
  zoom: null,
};

const filterDataSlice = createSlice({
  initialState: initialStateFilter,
  name: 'filterData',
  reducers: {
    initFilterData: (state) => {
      const newState = getFilterParamsFromURL();

      console.log(newState);

      return { ...state, ...newState, isLoaded: true };
    },
    resetFilterData: (state) => {
      const resetData = {
        ...initialStateFilter,
        showAvailable: state.showAvailable,
      };

      setFiltersParamsToURL(resetData);

      return resetData;
    },
    setFilterData: (state, action: PayloadAction<Partial<FilterData>>) => {
      const newState = { ...state, ...action.payload };

      if (!action.payload.page) {
        newState.page = 1;
      }
      if (state.isLoaded) {
        setFiltersParamsToURL(newState);
      }

      return newState;
    },
  },
});

export const { initFilterData, resetFilterData, setFilterData } =
  filterDataSlice.actions;

export default filterDataSlice.reducer;
