import {
  CalendarDayState,
  CalendarSelected,
  OverbookTypes,
} from '@shared/ui/Calendar';
import { CommonProps } from '@shared/ui/Calendar/types';

import {
  isBefore,
  isBetweenInterval,
  isSameDay,
  startOfDay,
} from './date.utils';
import { getProtectedTime } from './get-protected-time';

export interface GetProtectedIntervalReturn {
  interval: CalendarSelected[] | null;
  overbookType: OverbookTypes | null;
}

export function getProtectedInterval(
  date: Date,
  state: CalendarDayState,
  commonProps: CommonProps,
): GetProtectedIntervalReturn {
  const { range, reserved, selected } = commonProps;

  const validate = validateBooking(date, state, commonProps);

  if (validate) {
    return validate;
  }

  const [startDate, endDate] = selected;
  const isStart = range ? !startDate : commonProps.isStart;
  const freeTime = getProtectedTime(date, reserved);

  const newStart = isStart
    ? isSameDay(date, new Date())
      ? new Date()
      : freeTime.startDate
    : startDate;
  const newEnd = isStart ? endDate : freeTime.endDate;

  const interval = [newStart || null, newEnd || null];

  return { interval, overbookType: null };
}

const validateBooking = (
  date: Date,
  state: CalendarDayState,
  commonProps: CommonProps,
): GetProtectedIntervalReturn | null => {
  const { range, reserved, selected } = commonProps;
  const [startDate, endDate] = selected;
  const isStart = range ? !startDate : commonProps.isStart;

  const interval = [getProtectedTime(date, reserved).startDate];

  // if "PAST"
  if (state.isPast) {
    return { interval: null, overbookType: 'PAST' };
  }

  // if reserved between
  if (state.isReserved) {
    return { interval: null, overbookType: 'BOOKED' };
  }

  // if selected length 0 or 2 then set selected date
  if (range && ((!startDate && !endDate) || (startDate && endDate))) {
    return { interval, overbookType: null };
  }

  if (isStart) {
    // if selected start date after end date
    if (endDate && isBefore(endDate, date)) {
      return {
        interval: range ? interval : null,
        overbookType: 'AFTER_END',
      };
    }
  } else {
    // if selected end date before start date
    if (startDate && isBefore(date, startDate)) {
      return {
        interval: range ? interval : null,
        overbookType: 'BEFORE_START',
      };
    }
  }

  const isReservedBetween =
    startDate &&
    reserved.find((r) =>
      isBetweenInterval(r.startDate, r.endDate, startDate, startOfDay(date)),
    );

  // if booked beetwen start date and current date
  if (isReservedBetween) {
    return {
      interval: range || isStart ? interval : null,
      overbookType: 'BOOKED_BETWEEN',
    };
  }

  return null;
};
