import { FC } from 'react';
import cx from 'classnames';

import { Card, Container } from '@/shared';
import { useIsVerify } from '@entities/Auth/libs/hooks';
import { Spacer } from '@house-booking/ui-kit';
import { HouseWithExtendedTariffsResponse, TariffResponse } from '@shared/api';
import { useModal } from '@shared/libs';
import { HouseTariffs } from '@widgets/House/ui/HouseTariffs/HouseTariffs';

import { HouseCharsList } from '../HouseCharsList/HouseCharsList';
import { HousePreview } from '../HousePreview/HousePreview';

import styles from './styles.module.scss';

interface HouseDetailedCardProps {
  topSlot?: JSX.Element;
  house: HouseWithExtendedTariffsResponse;
}
export const HouseDetailedCard: FC<HouseDetailedCardProps> = ({
  house,
  topSlot,
}): JSX.Element => {
  const isVerify = useIsVerify();
  const openBookingModal = useModal('bookingHouse');
  const openVerifyAlertModal = useModal('verifyAlert');

  const handleBooking = (tariff: TariffResponse) => {
    if (isVerify) {
      return openBookingModal({ house_id: house.id!, tariff });
    }

    return openVerifyAlertModal();
  };

  return (
    <div className={styles.card}>
      <Spacer size={8} />
      <Container>
        {topSlot}
        <Spacer size={8} />
        <div className={styles.card__content}>
          <Card className={styles.card__wrapper}>
            <HousePreview
              house={house}
              className={cx(styles.card__block, styles['card__block--main'])}
            />
          </Card>
          <Card className={styles.card__wrapper}>
            <HouseCharsList
              className={styles.card__block}
              house={house}
              title={String(house.id)}
            />
          </Card>
        </div>
        <Spacer size={4} />
        <HouseTariffs tariffs={house.tariffs} onBooking={handleBooking} />
        <Spacer size={12} />
      </Container>
    </div>
  );
};
