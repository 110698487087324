import React, { ComponentPropsWithoutRef } from 'react';

import { getAttributes } from '@shared/ui/Calendar/helpers';

import { CommonProps } from '../types';

import {
  addDays,
  formatDate,
  isWeekend,
  startOfWeek,
} from '../utils/date.utils';

export type WeekContentProps = CommonProps & {
  day: number;
  innerProps?: Omit<ComponentPropsWithoutRef<'div'>, 'children'>;
};

export const WeekContent = (props: WeekContentProps) => {
  const { day, getClassNames, innerProps, options } = props;

  const startDate = startOfWeek(new Date(), options);
  const date = addDays(startDate, day);

  return (
    <div
      className={getClassNames('WeekContent')}
      {...getAttributes({ 'data-weekend': isWeekend(date) })}
      {...innerProps}>
      {formatDate(date, { weekday: 'short' }, options)}
    </div>
  );
};
