import React, { ComponentPropsWithoutRef, PropsWithChildren } from 'react';

import { CalendarDayState, CommonProps } from '../types';

export type DayLabelProps = CommonProps & {
  date: Date;
  state: CalendarDayState;
  innerProps?: ComponentPropsWithoutRef<'div'>;
};

export const DayLabel = (props: PropsWithChildren<DayLabelProps>) => {
  const { children, getClassNames, innerProps, state } = props;

  if (state.isPast) {
    return null;
  }

  return (
    <div className={getClassNames('DayLabel')} {...innerProps}>
      {children}
    </div>
  );
};
