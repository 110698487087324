import { FC, useMemo } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Map } from '@/shared';
import { RouterName } from '@app/router';
import { useAppDispatch, useAppSelector } from '@app/store';
import { setFilterData } from '@features/House';
import { HouseMapViewList, sdk } from '@shared/api';
import { getIsoDate } from '@shared/libs/utils/format';

export const HousesMap: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state) => state.filter);
  const { navigate } = useNavigate();
  const { data: curData, isSuccess } =
    sdk.useListHousesToRepresentOnMapWithFiltersHouseListForMapGetQuery({
      area: filters.area || undefined,
      block: filters.block || undefined,
      endDate: filters.end_date ? getIsoDate(filters.end_date) : undefined,
      isAvailable: filters.showAvailable ? true : undefined,
      maxCostPerDay: filters.max_cost_per_day,
      region: filters.region || undefined,
      startDate: filters.start_date
        ? getIsoDate(filters.start_date)
        : undefined,
    });
  const data = useMemo<HouseMapViewList>(
    () => (isSuccess ? curData : { edge: { total: 0 }, houses: [] }),
    [curData, isSuccess],
  );

  return (
    <Map
      initialZoom={filters.zoom}
      initialCoords={filters.coords}
      markers={data.houses}
      onMarkerClick={(id) => navigate(RouterName.House, { id })}
      onChangeCoords={(coords) => dispatch(setFilterData({ coords }))}
      onChangeZoom={(zoom) => dispatch(setFilterData({ zoom }))}
    />
  );
};
