export enum RouterName {
  NotFound = 'not-found',
  Login = 'login',
  Register = 'register',
  Filter = 'filter',
  History = 'history',
  HistoryDetail = 'history-detail',
  Profile = 'profile',
  Logout = 'logout',
  House = 'house',
  Admin = 'admin',
  AdminTariffs = 'admin-tariffs',
  AdminConfirmationUsers = 'admin-confirmation-users',
  AdminUsers = 'admin-users',
  AdminUser = 'admin-user',
  AdminConfirmUser = 'admin-confirm-user',
  AdminTariffCreate = 'admin-tariff-create',
  AdminTariffEdit = 'admin-tariff-edit',
  AdminHouses = 'admin-houses',
  AdminHouseEdit = 'admin-house-edit',
  AdminHouseCreate = 'admin-house-create',
}
export enum Role {
  USER = 'user',
  GHOST = 'ghost',
}
