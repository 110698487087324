import { FC, useState } from 'react';
import DatePicker from 'react-datepicker';
import { DateFilterOptions } from 'react-datepicker/dist/date_utils';
// eslint-disable-next-line import/no-named-as-default
import ru from 'date-fns/locale/ru';
import moment from 'moment';

import { Maybe, TextField } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

interface DateSingleInputProps {
  value: Maybe<Date>;
  onChange: (value: Maybe<Date>) => void;
  placeholder: string;
  excludeDateIntervals?: DateFilterOptions['excludeDateIntervals'];
  error?: Maybe<string>;
}
export const DateSingleInput: FC<DateSingleInputProps> = ({
  error,
  excludeDateIntervals,
  onChange,
  placeholder,
  value,
}): JSX.Element => {
  const [curValue, setCurValue] = useState<Maybe<Date>>(value);
  const hasValue = !!curValue;

  const handleUpdate = (val: Maybe<Date>) => {
    onChange(val);
    setCurValue(val);
  };

  return (
    <DatePicker
      onChange={handleUpdate}
      selected={curValue}
      // @ts-ignore
      locale={ru}
      excludeDateIntervals={excludeDateIntervals}
      isClearable
      customInput={
        <div className={styles.datePicker}>
          <TextField
            className={styles.datePicker__input}
            value={hasValue ? `${moment(curValue).format('DD.MM.YYYY')}` : ''}
            label={hasValue ? '' : placeholder}
            error={error || undefined}
            readonly
            fullWidth
          />
        </div>
      }
    />
  );
};
