import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { store } from '@app/store';
import { YMaps } from '@pbe/react-yandex-maps';

import { Root } from './app';

import 'react-datepicker/dist/react-datepicker.css';
import '@shared/ui/Calendar/stylesheets/calendar.scss';

const rootNode = document.querySelector('#root')!;
const root = createRoot(rootNode);

root.render(
  <Provider store={store}>
    <YMaps>
      <Root />
    </YMaps>
  </Provider>,
);
