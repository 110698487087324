import { FC, useEffect } from 'react';
// @ts-ignore
import { LightgalleryItem } from 'react-lightgallery';
import moment from 'moment';

import { ActionButtons, Container, FieldLabel } from '@/shared';
import { useIsSuperAdmin } from '@entities/Auth/libs/hooks';
import {
  LoaderOverlay,
  Spacer,
  TextField,
  toaster,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { useModal } from '@shared/libs';

import styles from './styles.module.scss';

export interface AdminConfirmUserProps {
  id: number;
  onCancel(): void;
}
export const AdminConfirmUser: FC<AdminConfirmUserProps> = ({
  id,
  onCancel,
}) => {
  const isSuperAdmin = useIsSuperAdmin();
  const { data, isLoading } =
    sdk.useGetUserVerificationInfoByIdUserUserIdUserIdGetQuery({
      userId: id,
    });
  const [getDocsImg, { data: docsImg }] =
    sdk.useGetDocumentImagesImagesGetDocsImagesPostMutation();
  const openVerifyModal = useModal('verifyUser');
  const openDeactivateModal = useModal('deactivateUser');
  const onDeactivate = () => {
    if (!data) {
      return toaster.error({
        title:
          'В данный момент деактивация не доступная для данного пользователя',
      });
    }
    const { firstname, patronymic, surname } = data;

    openDeactivateModal({
      fio: `${surname} ${firstname} ${patronymic}`,
      id,
      onSuccess: onCancel,
    });
  };
  const onVerify = () => {
    if (!data) {
      return toaster.error({
        title:
          'В данный момент верификация не доступная для данного пользователя',
      });
    }
    const { firstname, patronymic, surname } = data;

    openVerifyModal({
      fio: `${surname} ${firstname} ${patronymic}`,
      id,
      onSuccess: onCancel,
    });
  };

  useEffect(() => {
    if (data?.doc_scan_keys) {
      getDocsImg({ imageKeysList: { keys: data.doc_scan_keys } });
    }
  }, [data?.doc_scan_keys]);

  return (
    <div>
      <ActionButtons
        buttons={[
          {
            label: 'Отменить',
            onClick: onCancel,
          },
          ...(isSuperAdmin && data?.is_active
            ? [
                {
                  label: 'Деактивировать',
                  onClick: onDeactivate,
                },
              ]
            : []),
          ...(data?.is_active
            ? []
            : [
                {
                  label: 'Верифицировать',
                  onClick: onVerify,
                },
              ]),
        ]}
      />
      <Spacer size={20} />
      <Container className="relative">
        <LoaderOverlay show={isLoading} />
        {!!docsImg?.images?.length && (
          <>
            <div className={styles.confirmUser__images}>
              {(docsImg?.images || []).map((el, idx) => (
                <div className={styles.confirmUser__imgItem} key={idx}>
                  <LightgalleryItem src={el.url}>
                    <img
                      className={styles.confirmUser__img}
                      src={el.url}
                      alt="docs"
                    />
                  </LightgalleryItem>
                </div>
              ))}
            </div>
            <Spacer size={10} />
          </>
        )}
        <div className={styles.confirmUser__row}>
          <FieldLabel label="Дата регистрации">
            <TextField
              value={moment(data?.created_at).format('DD.MM.YYYY HH:mm')}
              readonly
              fullWidth
            />
          </FieldLabel>
          <FieldLabel label="Имя">
            <TextField value={data?.firstname} readonly fullWidth />
          </FieldLabel>
          <FieldLabel label="Фамилия">
            <TextField value={data?.surname} readonly fullWidth />
          </FieldLabel>
          <FieldLabel label="Отчество">
            <TextField value={data?.patronymic} readonly fullWidth />
          </FieldLabel>
        </div>
      </Container>
    </div>
  );
};
