import { useEffect, useState } from 'react';

import { PreBookingPriceCalculation, sdk } from '@shared/api';

export const useBookingHousePrice = (
  params: PreBookingPriceCalculation | null,
) => {
  const [price, setPrice] = useState<number>(0);
  const [calculatePrice, { data, isLoading }] =
    sdk.useCalculateBookingPriceBookingCalculateBookingPricePostMutation();

  useEffect(() => {
    if (params) {
      calculatePrice({
        preBookingPriceCalculation: params,
      });
    }
  }, [params]);

  useEffect(() => {
    if (data?.total_price) {
      setPrice(data.total_price);
    }
  }, [data]);

  return {
    isLoading,
    price,
  };
};
