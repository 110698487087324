import { FC, useEffect, useState } from 'react';

import { ActionButtons, FloatDriver, Head } from '@/shared';
import { AdminHouseForm, AdminHouseTariffDatePrice } from '@entities/Admin';
import {
  LoaderOverlay,
  Maybe,
  Spacer,
  toaster,
  Typography,
} from '@house-booking/ui-kit';
import { HouseCreate, HouseUpdate, sdk } from '@shared/api';
import { useModal } from '@shared/libs';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

import styles from './styles.module.scss';

export interface AdminHouseEditProps {
  id: number;
  onCancel(): void;
}
export const AdminHouseEdit: FC<AdminHouseEditProps> = ({ id, onCancel }) => {
  const [hasError, setHasError] = useState<boolean>(false);
  const openActivateModal = useModal('activateHouse');
  const openDeactivateModal = useModal('deactivateHouse');
  const [editedPriceTariff, setEditedPriceTariff] = useState<Maybe<number>>();
  const { data } = sdk.useGetHouseByIdHouseGetIdGetQuery({
    id: +id,
  });
  const [
    updateHouse,
    {
      data: resultUpdateData,
      error: errorUpdateData,
      isLoading: isLoadingUpdate,
    },
  ] = sdk.useUpdateHouseHouseUpdatePostMutation();
  const [updateData, setUpdateData] = useState<HouseUpdate>();
  const handleSave = () => {
    if (!updateData) {
      return toaster.error({ title: 'Данные для сохранения отсутствуют' });
    }
    updateHouse({ houseUpdate: updateData });
  };

  useEffect(() => {
    if (resultUpdateData) {
      toaster.success({ title: 'Дом успешно сохранён' });
    } else if (errorUpdateData) {
      toaster.error({
        contentSlot: isFetchBaseQueryError(errorUpdateData) ? (
          <Typography variant="body-14" color="on-surface-primary-2">
            {errorUpdateData.data.error_details}
          </Typography>
        ) : undefined,
        title: 'Ошибка сохранения дома',
      });
    }
  }, [resultUpdateData, errorUpdateData]);

  const handleCloseTariffPriceEditor = () => {
    setEditedPriceTariff(null);
  };

  if (!data) {
    return <></>;
  }

  return (
    <div>
      <LoaderOverlay show={isLoadingUpdate} />
      <ActionButtons
        buttons={[
          {
            label: 'Отменить',
            onClick: onCancel,
          },
          data.available
            ? {
                disabled: hasError,
                label: 'Снять с публикации',
                onClick: () => openDeactivateModal({ id }),
              }
            : {
                disabled: hasError,
                label: 'Опубликовать',
                onClick: () => openActivateModal({ id }),
              },
          {
            disabled: hasError,
            label: 'Сохранить изменения',
            onClick: handleSave,
          },
        ]}
      />
      <AdminHouseForm
        initialValues={data as HouseCreate}
        onChange={setUpdateData}
        onChangeHasError={setHasError}
        onEditPrice={setEditedPriceTariff}
        editPrice
      />
      <FloatDriver
        isOpen={!!editedPriceTariff}
        onClose={handleCloseTariffPriceEditor}>
        <div className={styles.houseEdit__tariffPrice}>
          <Head
            title="Редактирование цен"
            onBack={handleCloseTariffPriceEditor}
          />
          <Spacer size={12} />
          {editedPriceTariff && (
            <AdminHouseTariffDatePrice
              houseId={id}
              tariffId={editedPriceTariff}
            />
          )}
        </div>
      </FloatDriver>
    </div>
  );
};
