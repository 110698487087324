import { RoleEnum } from '@entities/Auth';
import { sdk } from '@shared/api';

export const useIsAdmin = () => {
  const { data: user } = sdk.useMeUserMeGetQuery();

  return [
    RoleEnum.SUPER_ADMIN,
    RoleEnum.USER_ADMIN,
    RoleEnum.HOUSE_BOOKING_ADMIN,
    RoleEnum.HARDWARE_ADMIN,
    RoleEnum.SERVICE_ADMIN,
    RoleEnum.ACCOUNT_CONFIRMATION_ADMIN,
  ].includes((user?.role || '') as RoleEnum);
};
