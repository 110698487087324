import { RoleEnum } from '@entities/Auth';
import { sdk } from '@shared/api';

export const useIsAccountConfirmAdmin = () => {
  const { data: user } = sdk.useMeUserMeGetQuery();

  return [RoleEnum.ACCOUNT_CONFIRMATION_ADMIN].includes(
    (user?.role || '') as RoleEnum,
  );
};
