import React, {
  ComponentPropsWithoutRef,
  CSSProperties,
  PropsWithChildren,
} from 'react';

import { getAttributes } from '@shared/ui/Calendar/helpers';
import { formatDate } from '@shared/ui/Calendar/utils/date.utils';

import { CalendarDayState, ClickDayHandler, CommonProps } from '../types';

// ==============================
// Calendar Container
// ==============================

export type CalendarContainerProps = CommonProps &
  PropsWithChildren & {
    innerProps: ComponentPropsWithoutRef<'div'> & { isScrollable?: boolean };
  };

export const CalendarContainer = (props: CalendarContainerProps) => {
  const { children, getClassNames, innerProps } = props;
  const { className = '', isScrollable, ...restInner } = innerProps ?? {};

  const classNames = getClassNames('CalendarContainer', className);

  return (
    <div
      className={classNames}
      {...getAttributes({ 'data-scrollable': !!isScrollable })}
      {...restInner}>
      {children}
    </div>
  );
};

// ==============================
// Month Container
// ==============================

export type MonthContainerProps = CommonProps &
  PropsWithChildren & {
    innerProps?: { style?: CSSProperties };
  };

export const MonthContainer = (props: MonthContainerProps) => {
  const { children, getClassNames, innerProps } = props;

  return (
    <div className={getClassNames('MonthContainer')} {...innerProps}>
      {children}
    </div>
  );
};

// ==============================
// Week Container
// ==============================

export type WeekContainerProps = CommonProps &
  PropsWithChildren & {
    innerProps?: {};
  };

export const WeekContainer = (props: WeekContainerProps) => {
  const { children, getClassNames } = props;

  return <div className={getClassNames('WeekContainer')}>{children}</div>;
};

// ==============================
// Days Container
// ==============================

export type DaysContainerProps = CommonProps &
  PropsWithChildren & {
    innerProps?: {};
  };

export const DaysContainer = (props: DaysContainerProps) => {
  const { children, getClassNames } = props;

  return (
    <div role="listbox" className={getClassNames('DaysContainer')}>
      {children}
    </div>
  );
};

// ==============================
// Day Container
// ==============================

export type DayContainerProps = CommonProps &
  PropsWithChildren & {
    date: Date;
    state: CalendarDayState;
    innerProps?: {
      style?: CSSProperties;
      onClick: ClickDayHandler;
    };
  };

export const DayContainer = (props: DayContainerProps) => {
  const { children, date, getClassNames, innerProps, options, state } = props;
  const { onClick, ...restInner } = innerProps ?? {};

  const attributes = getAttributes({
    'data-end-month': !!state.isEndMonth,
    'data-past': !!state.isPast,
    'data-reserved': !!state.isReserved,
    'data-selected':
      !!state.isSelected || !!state.isSelectedStart || !!state.isSelectedEnd,
    'data-start-month': !!state.isStartMonth,
  });

  return (
    <div
      aria-label={formatDate(date, {}, options)}
      tabIndex={-1}
      className={getClassNames('DayContainer')}
      onClick={() => onClick && onClick(date, state)}
      {...attributes}
      {...restInner}>
      {children}
    </div>
  );
};
