import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { useIsAdmin, useIsVerify } from '@entities/Auth/libs/hooks';
import {
  Avatar,
  Divider,
  Dropdown,
  DropdownItem,
  Paper,
  Typography,
} from '@house-booking/ui-kit';
import styles from '@widgets/Layout/ui/Header/styles.module.scss';

export const ProfileDropdown: FC = () => {
  const { navigate } = useNavigate();
  const isVerify = useIsVerify();
  const isAdmin = useIsAdmin();

  return (
    <Dropdown
      targetSlot={<Avatar name="M" className={styles.header__avatar} />}
      width={220}
      placement="bottom-end">
      <Paper px={4} py={4} radius={4}>
        <DropdownItem onClick={() => navigate(RouterName.Profile)} fullWidth>
          <Typography variant="body-16" color="on-surface-primary-1">
            Профиль
          </Typography>
        </DropdownItem>
        {isVerify && (
          <DropdownItem onClick={() => navigate(RouterName.History)} fullWidth>
            <Typography variant="body-16" color="on-surface-primary-1">
              История
            </Typography>
          </DropdownItem>
        )}
        {isAdmin && (
          <DropdownItem onClick={() => navigate(RouterName.Admin)} fullWidth>
            <Typography variant="body-16" color="on-surface-primary-1">
              Админ-панель
            </Typography>
          </DropdownItem>
        )}
        <Divider className={styles.profileControl__divider} />
        <DropdownItem onClick={() => navigate(RouterName.Logout)} fullWidth>
          <Typography variant="body-16" color="error-2">
            Выйти
          </Typography>
        </DropdownItem>
      </Paper>
    </Dropdown>
  );
};
