import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithRefresh } from '@shared/api/baseQuery';
import { MainBaseQueryFn } from '@shared/api/types';

export enum TagTypes {
  House = 'House',
  User = 'User',
  Operation = 'Operation',
  Booking = 'Booking',
  Tariff = 'Tariff',
  UserAdmin = 'UserAdmin',
  TariffPriceAdmin = 'TariffPriceAdmin',
}
export const apiClient = createApi({
  baseQuery: baseQueryWithRefresh<MainBaseQueryFn>('/api/api_v1'),
  endpoints: () => ({}),
  reducerPath: 'api',
});
