import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { filterDataReducer } from '@features/House';
import {
  combineReducers,
  configureStore,
  ConfigureStoreOptions,
} from '@reduxjs/toolkit';
import { apiClient } from '@shared/api';

export const rootReducer = combineReducers({
  [apiClient.reducerPath]: apiClient.reducer,
  filter: filterDataReducer,
});
export const createStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined,
) =>
  configureStore({
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      apiClient.middleware,
    ],
    reducer: rootReducer,
    ...options,
  });

export const store = createStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
