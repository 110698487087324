import { useMemo } from 'react';
import moment from 'moment/moment';

import { sdk } from '@shared/api';
import { CalendarReserved } from '@shared/ui/Calendar';

export const useReservedHouseCalendarDates = (houseId: number) => {
  const { data: disabledDatesData } =
    sdk.useGetUnavailableBookingDatesForHouseBookingGetUnavailableDatesForHouseGetQuery(
      { houseId },
    );

  return useMemo<CalendarReserved[]>(
    () =>
      (disabledDatesData?.unavailable_dates || []).reduce<CalendarReserved[]>(
        (acc, el) => {
          if (el[0] && el[1]) {
            acc.push({
              endDate: new Date(el[1]),
              startDate: moment(new Date(el[0])).subtract(1, 'days').toDate(),
            });
          }

          return acc;
        },
        [],
      ),
    [disabledDatesData],
  );
};
