import { FC, useState } from 'react';

import { Container } from '@/shared';
import {
  AdminUsersFilters,
  AdminUsersListFilter,
  AdminUsersTable,
} from '@features/Admin';
import { Spacer } from '@house-booking/ui-kit';

export const AdminUsersList: FC = () => {
  const [filters, setFilters] = useState<AdminUsersFilters>();

  return (
    <Container>
      <AdminUsersListFilter onChange={setFilters} />
      <Spacer size={10} />
      <AdminUsersTable filters={filters || {}} />
    </Container>
  );
};
