import { FC, PropsWithChildren } from 'react';

import { Driver, Overlay } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface FloatDriverProps {
  isOpen: boolean;
  onClose(): void;
}
export const FloatDriver: FC<PropsWithChildren<FloatDriverProps>> = ({
  children,
  isOpen,
  onClose,
}) => {
  if (!isOpen) {
    return <></>;
  }

  return (
    <div className={styles.floatDriver}>
      <Overlay
        className={styles.floatDriver__overlay}
        onClick={() => onClose()}
        zIndex={0}
      />
      <Driver px={0} className={styles.floatDriver__driver}>
        {children}
      </Driver>
    </div>
  );
};
