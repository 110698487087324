import React, { ComponentPropsWithoutRef } from 'react';

import { CalendarDayState, CommonProps } from '../types';

import { getDayAttributes } from '../helpers';

export type DaySelectionProps = CommonProps & {
  date: Date;
  state: CalendarDayState;
  innerProps?: ComponentPropsWithoutRef<'div'>;
};

export const DaySelection = (props: DaySelectionProps) => {
  const { getClassNames, innerProps, options, state } = props;

  if (!isShow(state)) {
    return null;
  }

  return (
    <div
      className={getClassNames('DaySelection')}
      {...getDayAttributes(state, options)}
      {...innerProps}
    />
  );
};

const isShow = (state: CalendarDayState) =>
  state.isSelected || state.isSelectedStart || state.isSelectedEnd;
