import { FC } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Head } from '@/shared';
import { RouterName } from '@app/router';
import { AdminConfirmUser } from '@features/Admin';

export interface AdminUserViewProps {
  params: {
    id: number;
  };
}
export const AdminUserView: FC<AdminUserViewProps> = ({ params: { id } }) => {
  const { navigate } = useNavigate();

  const handleCancel = () => {
    navigate(RouterName.AdminUsers);
  };

  return (
    <div>
      <Head title="Пользователь" onBack={handleCancel} />
      <AdminConfirmUser id={id} onCancel={handleCancel} />
    </div>
  );
};
