import React, { FC, useEffect } from 'react';

import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

interface AdminVerifyUserModalProps {
  id: number;
  fio: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    deactivateUser: AdminVerifyUserModalProps;
  }
}
export const AdminDeactivateUserModal: FC<
  WithCloseModal<AdminVerifyUserModalProps>
> = ({ fio, id, onClose, onSuccess }): JSX.Element => {
  const [deactivateUser, { data, error }] =
    sdk.useDeactivateUserUserDeactivatePostMutation();

  useEffect(() => {
    if (data) {
      toaster.success({ title: 'Пользователь успешно деактивирован' });
      onClose();
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({ title: 'Ошибка деактивации пользователя' });
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Вы действительно хотите деактивировать пользователя ${fio}?`}
      closed
      width={480}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button
              fullWidth
              color="danger"
              onClick={() =>
                deactivateUser({ userDeactivate: { user_id: id } })
              }>
              Деактивировать
            </Button>
          }
        />
      }
    />
  );
};
