import React, { FC, useMemo, useState } from 'react';

import { FieldLabel } from '@/shared';
import {
  useBookingHouseCreate,
  useBookingHousePrice,
  usePricingHouseCalendarDates,
  useReservedHouseCalendarDates,
} from '@features/House/libs/hooks';
import {
  Button,
  Dialog,
  DialogFooter,
  LoaderOverlay,
  Spacer,
  Typography,
  WithCloseModal,
} from '@house-booking/ui-kit';
import { sdk, TariffResponse } from '@shared/api';
import { formatAmount, getIsoDate } from '@shared/libs/utils/format';
import { Calendar, CalendarDay, CalendarSelected } from '@shared/ui/Calendar';

import styles from './styles.module.scss';

interface BookingHouseModalProps {
  house_id: number;
  tariff: TariffResponse;
}
declare global {
  interface ModalProps {
    bookingHouse: BookingHouseModalProps;
  }
}
export const BookingHouseModal: FC<WithCloseModal<BookingHouseModalProps>> = ({
  house_id,
  onClose,
  tariff,
}): JSX.Element => {
  const { data: user } = sdk.useMeUserMeGetQuery();
  const [activeDays, setActiveDays] = useState<CalendarDay[]>();
  const [selected, setSelected] = useState<CalendarSelected[]>([]);
  const pricingHouseParams = useMemo(
    () => ({
      activeInterval: activeDays?.length
        ? ([activeDays[0].date, activeDays.at(-1)!.date] as [Date, Date])
        : null,
      houseId: house_id,
      tariffId: Number(tariff.id),
    }),
    [activeDays, house_id, tariff],
  );
  const [createBooking, { error }] = useBookingHouseCreate({
    onSuccess: () => onClose(),
  });
  const labels = usePricingHouseCalendarDates(pricingHouseParams);
  const reserved = useReservedHouseCalendarDates(house_id);

  const bookingPayload = useMemo(
    () => ({
      end_date: getIsoDate(new Date(selected[1]!)),
      house_id,
      start_date: getIsoDate(new Date(selected[0]!)),
      tariff_id: tariff.id!,
    }),
    [selected, house_id, tariff],
  );
  const { isLoading: isLoadingPrice, price } = useBookingHousePrice(
    selected[1] ? bookingPayload : null,
  );

  const handleSubmit = () => {
    createBooking({
      ...bookingPayload,
      user_id: user!.id,
    });
  };

  return (
    <Dialog
      title={`Выберите даты бронирования дома ${house_id} по тарифу ${tariff.tariff_name}`}
      closed
      width={480}
      footerSlot={
        <DialogFooter
          fullWidth
          startSlot={
            <Button color="secondary" fullWidth onClick={onClose}>
              Отмена
            </Button>
          }
          endSlot={
            <Button
              fullWidth
              onClick={handleSubmit}
              disabled={!selected[0] || !selected[1]}>
              Забронировать
            </Button>
          }
        />
      }>
      <LoaderOverlay show={isLoadingPrice} />
      <FieldLabel error={error}>
        <Calendar
          selected={selected}
          reserved={reserved}
          labels={labels}
          range
          protection
          options={{ locale: 'ru', useAttributes: true, weekStartsOn: 1 }}
          onChange={setSelected}
          onChangeActiveDays={setActiveDays}
          disabled
          hideLabelOnReserved
        />
      </FieldLabel>
      <Spacer size={8} />
      <div className={styles.bookingHouse__btm}>
        <Typography variant="head-20">Итоговая цена:</Typography>
        <Typography variant="head-24">
          {price ? formatAmount(price, true) : '-'} ₽
        </Typography>
      </div>
    </Dialog>
  );
};
