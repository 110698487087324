import { FC, PropsWithChildren } from 'react';

import { Typography } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export interface FieldLabelProps {
  label?: string;
  error?: string | string[] | null;
}
export const FieldLabel: FC<PropsWithChildren<FieldLabelProps>> = ({
  children,
  error,
  label,
}) => (
  <div className={styles.fieldLabel}>
    {label && (
      <Typography variant="body-14" color="on-surface-primary-2">
        {label}
      </Typography>
    )}
    {children}
    {error && (
      <Typography
        className={styles.fieldLabel__error}
        variant="body-14"
        color="error-2">
        {error}
      </Typography>
    )}
  </div>
);
