import { FC, PropsWithChildren } from 'react';
import { Redirect } from 'react-justanother-router';

import { RouterName } from '@app/router';
import {
  useIsAccountConfirmAdmin,
  useIsHouseEditor,
} from '@entities/Auth/libs/hooks';
import { LoaderOverlay } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';
import { AdminLayout } from '@widgets/Layout';

export const AdminUserConfirmLayout: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  const { isLoading } = sdk.useMeUserMeGetQuery();
  const isAccountConfirmAdmin = useIsAccountConfirmAdmin();
  const isHouseEditor = useIsHouseEditor();

  if (isLoading) {
    return <LoaderOverlay show />;
  }
  if (!isAccountConfirmAdmin && !isHouseEditor) {
    return <Redirect to={RouterName.Admin} />;
  }

  return <AdminLayout>{children}</AdminLayout>;
};
