import { FC } from 'react';

import { Head } from '@/shared';
import { AdminUsersList } from '@widgets/Admin';

export const AdminUsersView: FC = () => (
  <div>
    <Head title="Список пользователей" />
    <AdminUsersList />
  </div>
);
