import { FC } from 'react';

import { RouterName } from '@app/router';
import {
  useIsAccountConfirmAdmin,
  useIsHouseEditor,
  useIsSuperAdmin,
} from '@entities/Auth/libs/hooks';
import { NavItem, useRouterPath } from '@house-booking/ui-kit';

import styles from './styles.module.scss';

export const AdminNavigation: FC = () => {
  const { checkActivePath } = useRouterPath();
  const isSuperAdmin = useIsSuperAdmin();
  const isHouseEditor = useIsHouseEditor();
  const isAccountConfirmAdmin = useIsAccountConfirmAdmin();

  return (
    <nav className={styles.nav}>
      {isHouseEditor && (
        <>
          {' '}
          <NavItem
            to={RouterName.AdminHouses}
            active={checkActivePath(RouterName.AdminHouses)}
            fullWidth>
            Дома
          </NavItem>
          <NavItem
            to={RouterName.AdminTariffs}
            active={checkActivePath(RouterName.AdminTariffs)}
            fullWidth>
            Тарифы
          </NavItem>
        </>
      )}
      {isSuperAdmin ? (
        <NavItem
          to={RouterName.AdminUsers}
          active={checkActivePath(RouterName.AdminUsers)}
          fullWidth>
          Пользователи
        </NavItem>
      ) : isAccountConfirmAdmin || isHouseEditor ? (
        <NavItem
          to={RouterName.AdminConfirmationUsers}
          active={checkActivePath(RouterName.AdminConfirmationUsers)}
          fullWidth>
          Пользователи
        </NavItem>
      ) : (
        <></>
      )}
    </nav>
  );
};
