import { ComponentType } from 'react';

import {
  DayLabel,
  DayLabelProps,
} from '@shared/ui/Calendar/components/DayLabel';

import {
  CalendarContainer,
  CalendarContainerProps,
  DayContainer,
  DayContainerProps,
  DaysContainer,
  DaysContainerProps,
  MonthContainer,
  MonthContainerProps,
  WeekContainer,
  WeekContainerProps,
} from './containers';
import { DayContent, DayContentProps } from './DayContent';
import { DayReservation, DayReservationProps } from './DayReservation';
import { DaySelection, DaySelectionProps } from './DaySelection';
import { DayToday, DayTodayProps } from './DayToday';
import { MonthArrowBack, MonthArrowBackProps } from './MonthArrowBack';
import { MonthArrowNext, MonthArrowNextProps } from './MonthArrowNext';
import { MonthContent, MonthContentProps } from './MonthContent';
import { WeekContent, WeekContentProps } from './WeekContent';

export interface CalendarComponentsBase {
  CalendarContainer: ComponentType<CalendarContainerProps>;
  MonthContainer: ComponentType<MonthContainerProps>;
  MonthContent: ComponentType<MonthContentProps>;
  MonthArrowBack: ComponentType<MonthArrowBackProps>;
  MonthArrowNext: ComponentType<MonthArrowNextProps>;
  WeekContainer: ComponentType<WeekContainerProps>;
  WeekContent: ComponentType<WeekContentProps>;
  DaysContainer: ComponentType<DaysContainerProps>;
  DayContainer: ComponentType<DayContainerProps>;
  DayContent: ComponentType<DayContentProps>;
  DaySelection: ComponentType<DaySelectionProps>;
  DayReservation: ComponentType<DayReservationProps>;
  DayToday: ComponentType<DayTodayProps>;
  DayLabel: ComponentType<DayLabelProps>;
}

export type CalendarComponents = Partial<CalendarComponentsBase>;
export type CalendarComponentsGeneric = typeof components;

export type CalendarClassNamesBase = Record<
  keyof CalendarComponentsBase,
  string
>;

export const componentClasses: CalendarClassNamesBase = {
  CalendarContainer: 'calendar-container',
  DayContainer: 'day-container',
  DayContent: 'day-content',
  DayLabel: 'day-label',
  DayReservation: 'day-reservation',
  DaySelection: 'day-selection',
  DayToday: 'day-today',
  DaysContainer: 'days-container',
  MonthArrowBack: 'month-arrow-back',
  MonthArrowNext: 'month-arrow-next',
  MonthContainer: 'month-container',
  MonthContent: 'month-content',
  WeekContainer: 'week-container',
  WeekContent: 'week-content',
};

export const components = {
  CalendarContainer: CalendarContainer,
  DayContainer: DayContainer,
  DayContent: DayContent,
  DayLabel: DayLabel,
  DayReservation: DayReservation,
  DaySelection: DaySelection,
  DayToday: DayToday,
  DaysContainer: DaysContainer,
  MonthArrowBack: MonthArrowBack,
  MonthArrowNext: MonthArrowNext,
  MonthContainer: MonthContainer,
  MonthContent: MonthContent,
  WeekContainer: WeekContainer,
  WeekContent: WeekContent,
};

export const defaultComponents = (
  componentsByProps: CalendarComponents = {},
): CalendarComponentsGeneric =>
  ({
    ...components,
    ...componentsByProps,
  }) as CalendarComponentsGeneric;
