import React, { ComponentPropsWithoutRef, ReactNode } from 'react';

import { CalendarDayState, CommonProps } from '../types';

import { getDayAttributes } from '../helpers';

export type DayContentProps = CommonProps & {
  date: Date;
  state: CalendarDayState;
  children: ReactNode;
  innerProps?: ComponentPropsWithoutRef<'div'>;
};

export const DayContent = (props: DayContentProps) => {
  const { children, getClassNames, innerProps, options, state } = props;

  return (
    <div
      className={getClassNames('DayContent')}
      {...getDayAttributes(state, options)}
      {...innerProps}>
      {children}
    </div>
  );
};
