import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { getProtectedInterval } from '@shared/ui/Calendar/utils/get-protected-interval';

import {
  CalendarDayState,
  CalendarLabel,
  CalendarMonth,
  CalendarPropsBase,
  CommonProps,
  MonthChangeHandler,
  YearChangeHandler,
} from './types';

import {
  CalendarComponentsBase,
  componentClasses,
  defaultComponents,
} from './components/index';
import {
  disabledInit,
  isStartInit,
  monthInit,
  rangeInit,
  reservedInit,
  selectedInit,
  yearInit,
} from './constants';
import {
  cn,
  createMonthDays,
  getDayState,
  getSelectedDates,
  isNumeric,
  preProtection,
} from './helpers';

export type CalendarProps = CalendarPropsBase & {
  initialDate?: Date | number | null;
  month?: CalendarMonth;
  year?: number;
  onMonthChange?: MonthChangeHandler;
  onYearChange?: YearChangeHandler;
};

export function Calendar({
  classNames = {},
  components = {},
  disabled = disabledInit,
  hideLabelOnReserved,
  isStart = isStartInit,
  labels,
  month,
  onChange,
  onChangeActiveDays,
  onMonthChange,
  onOverbook,
  onYearChange,
  options = {},
  protection = true,
  range = rangeInit,
  reserved = reservedInit,
  selected = selectedInit,
  year,
  ...innerProps
}: CalendarProps): JSX.Element {
  const [activeMonth, setActiveMonth] = useState(monthInit);
  const [activeYear, setActiveYear] = useState(yearInit);

  const currentMonth = useMemo(
    () => (isNumeric(month) ? month! : activeMonth),
    [month, activeMonth],
  );
  const currentYear = useMemo(
    () => (isNumeric(year) ? year! : activeYear),
    [year, activeYear],
  );

  const isControlled = isNumeric(month) || isNumeric(year);

  const [startDate, endDate] = getSelectedDates(selected);

  // ==============================
  // getClassNames
  // ==============================

  const getClassNames = <Key extends keyof CalendarComponentsBase>(
    name: Key,
    classes?: string,
  ) => {
    const defaultClass = `calendar__${componentClasses[name]}`;
    const propsClasses = classNames[name];

    return cn(defaultClass, propsClasses, classes);
  };

  const labelsDict = useMemo(() => {
    if (!labels) {
      return {};
    }
    const labelsDict: Record<number, CalendarLabel['label']> = {};

    for (const label of labels) {
      labelsDict[+moment(Number(label.date)).startOf('day').toDate()] =
        label.label;
    }

    return labelsDict;
  }, [labels]);

  // ==============================
  // commonProps
  // ==============================

  const commonProps: CommonProps = {
    disabled,
    getClassNames,
    isStart,
    options,
    protection,
    range,
    reserved,
    selected: [startDate, endDate],
  };

  // ==============================
  // handleMonthChange
  // ==============================

  const handleChangeMonth = (value: CalendarMonth) => {
    const [newMonth, newYear] = getMonthYear(value, currentYear);

    if (onMonthChange) {
      onMonthChange(newMonth, newYear ?? currentYear);
      if (newYear && onYearChange) {
        onYearChange(newYear);
      }

      if (isControlled) {
        return;
      }
    }

    setActiveMonth(newMonth);
    if (newYear) {
      setActiveYear(newYear);
    }
  };

  // ==============================
  // handleClickDay
  // ==============================

  const handleClickDay = (date: Date, state: CalendarDayState) => {
    const preSelected = preProtection(date, state, commonProps);

    if (preSelected !== null) {
      if (onChange) {
        onChange(preSelected);
      }

      return;
    }

    const { interval, overbookType } = getProtectedInterval(
      date,
      state,
      commonProps,
    );

    if (overbookType && !interval) {
      if (onOverbook) {
        onOverbook(date, overbookType);
      }

      return;
    }

    if (interval && onChange) {
      onChange(interval);
    }
  };

  // ==============================
  // Import components
  // ==============================

  const {
    CalendarContainer,
    DayContainer,
    DayContent,
    DayLabel,
    DayReservation,
    DaySelection,
    DayToday,
    DaysContainer,
    MonthArrowBack,
    MonthArrowNext,
    MonthContainer,
    MonthContent,
    WeekContainer,
    WeekContent,
  } = defaultComponents(components);

  const startMonth = useMemo(
    () => new Date(currentYear, currentMonth),
    [currentMonth, currentYear],
  );
  const days = useMemo(
    () => createMonthDays({ dateOfMonth: startMonth, options }),
    [startMonth],
  );

  useEffect(() => {
    onChangeActiveDays && onChangeActiveDays(days);
  }, [days]);

  return (
    <CalendarContainer {...commonProps} innerProps={{ ...innerProps }}>
      <MonthContainer {...commonProps}>
        <MonthArrowBack
          {...commonProps}
          innerProps={{
            onClick: () =>
              handleChangeMonth((currentMonth - 1) as CalendarMonth),
          }}
        />

        <MonthContent
          month={currentMonth}
          year={currentYear}
          {...commonProps}
        />

        <MonthArrowNext
          {...commonProps}
          innerProps={{
            onClick: () =>
              handleChangeMonth((currentMonth + 1) as CalendarMonth),
          }}
        />
      </MonthContainer>
      <WeekContainer {...commonProps}>
        {Array.from({ length: 7 }).map((_, key) => (
          <WeekContent
            key={`calendar_week_container_${key}`}
            day={key}
            {...commonProps}
            innerProps={{}}
          />
        ))}
      </WeekContainer>
      <DaysContainer {...commonProps}>
        {days.map((day, key) => {
          const dayProps = {
            date: day.date,
            state: getDayState({ day, disabled, reserved, selected }),
          };
          const label =
            hideLabelOnReserved && dayProps.state.isReserved
              ? null
              : labelsDict[+day.date];

          return (
            <DayContainer
              key={`calendar_day_container_${key}`}
              innerProps={{ onClick: handleClickDay }}
              {...dayProps}
              {...commonProps}>
              <DayContent {...dayProps} {...commonProps}>
                {day.date.getDate()}
              </DayContent>
              <DayToday {...dayProps} {...commonProps} />
              <DaySelection {...dayProps} {...commonProps} />
              <DayReservation {...dayProps} {...commonProps} />
              {label && (
                <DayLabel {...dayProps} {...commonProps}>
                  {label}
                </DayLabel>
              )}
            </DayContainer>
          );
        })}
      </DaysContainer>
    </CalendarContainer>
  );
}

const getMonthYear = (
  newMonth: CalendarMonth,
  year: number,
): [CalendarMonth, number | null] => {
  let month = newMonth;
  let newYear = null;

  if (newMonth > 11) {
    month = 0;
    newYear = year + 1;
  }
  if (newMonth < 0) {
    month = 11;
    newYear = year - 1;
  }

  return [month, newYear];
};
